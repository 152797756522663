// get the requested param value from the url
export function getURLParam(param) {
	const url = new URL(window.location);
	return url.searchParams.get(param);
}

// get the path from the url
export function getURLPath() {
	const url = new URL(window.location);
	return url.pathname.split(`/`)[1];
}

// create the url for the media object based on the firebase storage location
export function getMediaURL({ type, id, firebaseInstance }) {
	return `https://storage.googleapis.com/${firebaseInstance}.appspot.com/${type}/processed/${id}.${
		type === `videos` ? `mp4` : `png`
	}`;
}

export function getThumbnail({ id, firebaseInstance }) {
	return `https://storage.googleapis.com/${firebaseInstance}.appspot.com/thumbnails/${id}.png`;
}
