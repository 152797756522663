/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/core";

import "normalize.css";

import { getURLParam, getMediaURL, getThumbnail } from "../../libs/utils";

const firebaseInstance = `rick-and-morty-242714`;

const logo = `/img/logo.png`;
const adultswim = `/img/as.png`;
const imageText = `/img/imageText.png`;
const videoText = `/img/videoText.png`;
const twitter = `/img/Twitter.png`;
const facebook = `/img/Facebook.png`;
const footer = `/img/footer.png`;

const facebookAppID = `343932749878228`;

const mediaMessage = `I got my DNA scrambled at the #rickandmorty @adultswim #rickflector`
	.replace(/ /g, `%20`)
	.replace(/#/g, `%23`)
	.replace(/@/g, `%40`);

export default function index() {
	const [id, setID] = useState(null);
	const [imageLink, setImageLink] = useState(null);
	const [videoLink, setVideoLink] = useState(null);

	useEffect(() => {
		setID(getURLParam(`id`));
	}, []);

	useEffect(() => {
		const mediaWrapperURL = `https://media.rickflector.com`;
		setImageLink(`${mediaWrapperURL}/images?id=${id}`);
		setVideoLink(`${mediaWrapperURL}/videos?id=${id}`);
	}, [id]);

	const Container = ({ children }) => (
		<div
			css={css`
				width: 100%;
				background-image: linear-gradient(black, #075921, black);
				background-size: contain;

				font-family: neo-sans, Roboto, Impact, sans-serif;
				font-weight: 700;
				font-style: normal;

				a:link {
					text-decoration: none;
					color: #383838 !important;
				}

				a:visited {
					text-decoration: none;
					color: #383838 !important;
				}

				a:hover {
					text-decoration: none;
					color: #383838 !important;
				}

				display: flex;
				flex-direction: column;
				align-items: center;
			`}
		>
			{children}
		</div>
	);

	const HeaderBlock = () => (
		<>
			{/* rickflector logo */}
			<div
				css={css`
					padding-top: 40px;
				`}
			>
				<img
					src={logo}
					alt="Rick and Morty: Rickflector"
					width="400px"
					css={css`
						width: 400px;
						display: block;
					`}
				/>
			</div>

			{/* adult swim logo */}
			<div
				css={css`
					padding: 20px;
				`}
			>
				<a href="https://adultswim.com">
					<img
						src={adultswim}
						alt="Adult Swim"
						width="1500px"
						css={css`
							width: 150px;
							display: block;
							margin: 0 auto;
						`}
					/>
				</a>
			</div>
		</>
	);

	const ProfilePicBlock = () => (
		<div
			css={css`
				padding: 20px;
			`}
		>
			<div
				css={css`
					cursor: pointer;
				`}
				onClick={() => {
					return fetch(
						getMediaURL({
							type: `images`,
							id,
							firebaseInstance,
						}),
						{
							headers: new Headers({
								Origin: location.origin,
							}),
							mode: `cors`,
						}
					)
						.then((response) => response.blob())
						.then((blob) => {
							const a = document.createElement(`a`);
							a.download = `My_Rickflector_Pic.png`;
							a.href = window.URL.createObjectURL(blob);
							document.body.append(a);
							a.click();
							a.remove();
						});
				}}
			>
				{/* profile image text */}
				<img
					src={imageText}
					alt=""
					height="40px"
					css={css`
						height: 40px;
						display: block;
						margin: 0 auto 20px auto;
					`}
				/>

				{/* profile image */}
				<img
					src={getMediaURL({
						type: `images`,
						id,
						firebaseInstance,
					})}
					alt="Profile Pic"
					width="400px"
					css={css`
						width: 400px;
						display: block;
						margin: 10px auto;
						border: 3px solid #00c8e2;
						box-shadow: 0 0 20px #00c8e2, inset 0 0 20px #00c8e2;
					`}
				/>
			</div>

			<div
				css={css`
					width: 110px;
					margin: 0 auto;
				`}
			>
				<a
					href={`https://twitter.com/intent/tweet?text=${mediaMessage}&url=${imageLink}`}
				>
					<img
						src={twitter}
						alt="Twitter"
						width="30px"
						css={css`
							width: 30px;
							margin: 10px;
						`}
					/>
				</a>
				<a
					href={`https://www.facebook.com/dialog/share?app_id=${facebookAppID}&href=${imageLink}`}
				>
					<img
						src={facebook}
						alt="Facebook"
						width="30px"
						css={css`
							width: 30px;
							margin: 10px;
						`}
					/>
				</a>
			</div>
		</div>
	);

	const VideoBlock = () => (
		<div
			css={css`
				padding: 20px;
			`}
		>
			<div
				css={css`
					cursor: pointer;
				`}
				onClick={() => {
					return fetch(
						getMediaURL({
							type: `videos`,
							id,
							firebaseInstance,
						}),
						{
							headers: new Headers({
								Origin: location.origin,
							}),
							mode: `cors`,
						}
					)
						.then((response) => response.blob())
						.then((blob) => {
							const a = document.createElement(`a`);
							a.download = `My_Rickflector_Vid.mp4`;
							a.href = window.URL.createObjectURL(blob);
							document.body.append(a);
							a.click();
							a.remove();
						});
				}}
			>
				<img
					src={videoText}
					alt="View your Rickflector vid"
					width="219px"
					height="47px"
					css={css`
						width: 219px;
						height: 47px;
						display: block;
						margin: 0 auto 20px auto;
					`}
				/>

				<img
					src={getThumbnail({ id, firebaseInstance })}
					alt="Rickflector Video"
					width="400px"
					height="400px"
					css={css`
						width: 400px;
						height: 400px;
						display: block;
						margin: 10px auto;
						border: 3px solid #00c8e2;
						box-shadow: 0 0 20px #00c8e2, inset 0 0 20px #00c8e2;
					`}
				/>
			</div>
			<div
				css={css`
					width: 110px;
					margin: 0 auto;
				`}
			>
				<a
					href={`https://twitter.com/intent/tweet?text=${mediaMessage}&url=${videoLink}`}
					css={css`
						text-decoration: none;
					`}
				>
					<img
						src={twitter}
						alt="Twitter"
						width="30px"
						css={css`
							width: 30px;
							margin: 10px;
						`}
					/>
				</a>
				<a
					href={`https://www.facebook.com/dialog/share?app_id=${facebookAppID}&href=${videoLink}`}
					css={css`
						text-decoration: none;
					`}
				>
					<img
						src={facebook}
						alt="Facebook"
						width="30px"
						css={css`
							width: 30px;
							margin: 10px;
						`}
					/>
				</a>
			</div>
		</div>
	);

	const FooterBlock = () => (
		<>
			<div
				css={css`
					padding: 20px;
				`}
			>
				<a href="http://www.adultswim.com/videos/rick-and-morty">
					<img
						src={footer}
						alt="Rick and Morty"
						width="300px"
						css={css`
							width: 300px;
							display: block;
							margin: 0 auto;
						`}
					/>
				</a>
			</div>

			<span
				css={css`
					color: #292929;
					text-align: center;
					text-transform: uppercase;
					display: block;
					padding: 30px;
					font-size: 10px;
				`}
			>
				<p>
					You got your DNA scrambled at the Rick and Morty
					Rickflector!
					<br />
					Share your new and improved DNA.
				</p>
				<p>
					TM & ©2019 The Cartoon Network, Inc. A WarnerMedia Company.
					All Rights Reserved.
				</p>
				<p>
					<a
						href="https://www.adultswim.com/footer/legal/terms-of-use.html"
						css={css`
							color: #4d4d4d !important;
						`}
					>
						Terms of Use
					</a>
					{` `}|{` `}
					<a
						href="https://www.adultswim.com/footer/legal/privacy.html"
						css={css`
							color: #4d4d4d !important;
						`}
					>
						Privacy Policy
					</a>
				</p>
				<p>1050 Techwood Drive NW, Atlanta GA 30318</p>
			</span>
		</>
	);

	if (id === null) return <></>;
	return (
		<Container>
			<HeaderBlock />
			<ProfilePicBlock />
			<VideoBlock />
			<FooterBlock />
		</Container>
	);
}
